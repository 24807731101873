import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import NotificationDisplay from "./components/notification";
import Spinner from "./components/spinner";
import Monitor from "./containers/monitor";
import Main from "./containers/main";
import ExamplesPage from "./containers/examplesPage";
import BioMenu from "./components/header";
import ProjectGPS from "./components/projects/gps";
import * as serviceWorker from "./serviceWorker";

import { Layout } from "antd";

const { Header, Content, Footer } = Layout;

const store = configureStore();
const HeaderWithRouter = withRouter(BioMenu);
const MonitorWithRouter = withRouter(Monitor);
ReactDOM.render(
  <Provider store={store} key={"providerKey"}>
    <HashRouter>
      <MuiThemeProvider>
        <Layout className="layout">
          <Header>
            <div className="logo" />
            <MonitorWithRouter />
            <HeaderWithRouter/>
          </Header>
          <Content style={{ padding: "0px" }}>
            <div style={{ background: "#fff", padding: "0px 14px", minHeight: 280 }}>
              <Spinner />
              <Switch>
                <Route exact={true} path="/" component={Main} />
                <Route exact={true} path="/main" component={Main} />
                <Route exact={true} path="/examples" component={ExamplesPage} />
                <Route exact={true} path="/gps" component={ProjectGPS} />
              </Switch>
              <NotificationDisplay />
            </div>
          </Content>
          <Footer style={{ textAlign: "center", fontSize: "9px" }}>
            <a
              href="https://github.com/jameshadfield/phandango"
              target="_blank"
              style={{ color: "#c1c1c1" }}
            >
              fork from phandango
            </a>
            , Technical Support by 
            <a href="//iotabiome.com" target="_blank">
              iotabime.com
            </a>
            , 按p键下载当前绘图，按s键显示设置,按空格键搜索内容
          </Footer>
        </Layout>
      </MuiThemeProvider>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
