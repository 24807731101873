import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleSettings, searchInfo } from '../actions/general';
import { Menu, Icon, Button, AutoComplete, Input } from 'antd';

const { Option } = AutoComplete;

let current = 'home';
let loadingMsg = "meta信息搜索"
class BioMenu extends React.Component {
  constructor(...props) {
    super(...props);
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    showSettings: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    treeActive: PropTypes.bool.isRequired,
    annotationActive: PropTypes.bool.isRequired,
  }
  state = {
    dSource: [],
  };
  onSelect = (value) => {
    // const metadata = this.props.metadata.data
    const sinfo = value.split("@")
    // const {key,header}  = sinfo
    console.log('onSelect', sinfo);
    this.props.dispatch(searchInfo(sinfo[0], sinfo[1]));
  }

  searchResult = (query) => {
    const metadata = this.props.metadata.data
    const keys = Object.keys(metadata)
    const hNames = this.props.metadata.headerNames
    const values = this.props.metadata.values
    query = query.toUpperCase()
    let result = []
    keys.map((key) => {
      if (key.toUpperCase().includes(query)) {
        const keyIdx = keys.indexOf(key)
        let groups = []
        for (let i = 0; i < hNames.length; i++) {
          let name = hNames[i]
          let value = values[i][keyIdx]
          groups.push(name +" : "+ value)
        }
        result.push({
          query: key +"@" + hNames[0],
          key: key,
          groups: groups
        })
      }
    })
    return result
  }

  renderOption = (item) => {
    return (
      <Option key={item.query} text={item.query}>
        <div className="global-search-item">
          <span className="global-search-item-desc">
            {item.key}
            {/* {item.groups.join('，')} */}
          </span>
        </div>
      </Option >
    );
  }

  handleSearch = value => {
    this.setState({
      dSource: value ? this.searchResult(value) : [],
    });
  };

  handleClick = e => {
    current = e.key;
  };

  hideSettings() {
    if (this.props.showSettings) {
      this.props.dispatch(toggleSettings());
    }
  }
  maybeShowSettings() {
    if (this.props.location.pathname.split('/')[1] === 'main') {
      this.props.dispatch(toggleSettings());
    }
  }
  
  render() {
    const { dSource } = this.state;

    return (
      <div id="header">
        <div className="logo" />
        <Menu
          theme="while"
          mode="horizontal"
          defaultSelectedKeys={['home']}
          style={{ lineHeight: '64px' }}
          onClick={this.handleClick} selectedKeys={[current]}>
          <Menu.Item key="home">
            <Link to={'/main'} onClick={() => this.hideSettings()}>
              <Icon type="home" />
              Main
            </Link>
          </Menu.Item>
          <Menu.Item key="examples">
            <Link to={'/examples'} >
              <Icon type="appstore" />
              Examples
            </Link>
          </Menu.Item>
          <Menu.Item key="gps">
            <Link to={'/gps'} >
              <Icon type="database" />
              Gps
            </Link>
          </Menu.Item>
        </Menu>
        <div className="certain-category-search-wrapper" style={{ width: 350, lineHeight: '64px', position: 'absolute', top: 0, right: '15%', overflow: 'show' }}>
          <AutoComplete
            className="global-search"
            size="large"
            style={{ width: '100%' }}
            dataSource={dSource.map(this.renderOption)}
            onSelect={this.onSelect}
            onSearch={this.handleSearch}
            placeholder={loadingMsg}
            optionLabelProp="text"
          >
            <Input
              suffix={
                <Button
                  className="search-btn"
                  style={{ marginRight: -12 }}
                  size="large"
                  type="primary"
                >
                  <Icon type="search" />
                </Button>
              }
            />
          </AutoComplete>
        </div>
      </div>
    );
  }

}

// export default Header;
const mapStateToProps = (state) => ({
  showSettings: state.misc.showSettings,
  treeActive: state.layout.active.tree,
  annotationActive: state.layout.active.annotation,
  // searchKey: state.searchKey,
  metadata: state.metadata,
  activeTaxa: state.phylogeny.activeTaxa,
});

export default connect(mapStateToProps)(BioMenu);
export { BioMenu };
