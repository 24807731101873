import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import { compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

function getComposeEnhancers() {
  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    return composeWithDevTools;
  } else {
    return compose;
  }
}

export default function configureStore(initialState) {
  const middleware = [thunk];
  const composeEnhancers = getComposeEnhancers();
  const store = createStore(
    rootReducer,
    // 如果有初始状态 initial state，这里传入
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      // 可以在此处添加其他enhancer或者配置选项
    ),
  );
  return store;
}