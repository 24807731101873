
export function initCanvasXY() {
  this.canvas.setAttribute('width', window.getComputedStyle(this.canvas).width);
  this.canvas.setAttribute('height', window.getComputedStyle(this.canvas).height);
}

export function clearCanvas() {
  this.canvas.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
}

// http://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
export function getBrowser() {
  var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  // 检查是否手机浏览器
  let mobile = false
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    mobile = true;
  }
  if(/trident/i.test(M[1])){
    tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
    return {name:'IE',version:(tem[1]||'')};
  }
  if(M[1]==='Chrome'){
    tem=ua.match(/\bOPR\/(\d+)/)
    if(tem!=null)   {return {name:'Opera', version:tem[1]};}
  }
  M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}

  return {
    name: M[0],
    version: M[1],
    mobile,
  };
}
