import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Annotation from '../components/annotation';
import Drag from '../components/resize';
import Phylogeny from '../components/phylogeny';
import Blocks from '../components/blocks';
import Metadata from '../components/metadata';
import MetadataKey from '../components/metadataKey';
import Line from '../components/lineGraph';
import Gwas from '../components/gwasGraph';
import Cartoon from '../components/cartoonGenome';
import { Settings } from './settings';

class Main extends React.Component {
  static propTypes = {
    active: PropTypes.object.isRequired,
    colPercs: PropTypes.arrayOf(PropTypes.number).isRequired,
    rowPercs: PropTypes.array.isRequired,
    logoIsOn: PropTypes.bool.isRequired,
    showSettings: PropTypes.bool.isRequired,
  };
  constructor(...props) {
    super(...props);
    this.displayName = 'CanvasContainer';
    this.keyIdx = 0;
    this.key = 'canvases0';
    this.resizeFn = () => {
      this.forceUpdate(); // is this enough?
    };
    this.percentize = (n) => {
      return (n.toString() + '%');
    };
    this.makeVh = (n) => {
      return (n.toString() + 'vh');
    };
    this.getStyle = (colIdx, rowIdx, datatype = null) => {
      let height = 'calc(' + this.makeVh(this.props.rowPercs[rowIdx]) + ' - 3px)';
      // 特殊处理，让屏幕用满
      if (datatype === 'qq') {
        height = 'calc(85vh - 3px)';
      }
      const sty = {
        width: this.percentize(this.props.colPercs[colIdx]),
        height: height,
        position: 'relative',
        margin: '0px',
        cursor: 'crosshair',
      };
      if (colIdx < 2) {
        sty.float = 'left';
      } else {
        sty.float = 'right';
      }
      if (colIdx === 0 && rowIdx === 2) {
        sty.position = 'absolute';
        sty.bottom = '30px';
        sty.left = '10px';
      }
      if (datatype === 'cartoon') {
        sty.cursor = 'move';
      }
      return (sty);
    };
  }

  componentDidUpdate() {
    // console.log(this.props.active)
    window.dispatchEvent(new Event('resize')); // same event as window resizing :)
  }

  render() {
    const active = this.props.active;
    // deebug this
    if (!Object.keys(active).some((e) => active[e])) {
      return false;
    }
    // top row (small genome / ??? / annotation)
    const topRow = [];
    if (active.blocks || active.annotation) {
      topRow[0] = <Cartoon style={this.getStyle(0, 0, 'cartoon')} key={'cartoon'} />;
    } else {
      topRow[0] = <div style={this.getStyle(0, 0, 'cartoon-2')} key={'cartoon'} />;
    }
    topRow[1] = <div style={this.getStyle(1, 0, 'aboveMeta')} key={'aboveMeta'} />;
    if (active.annotation) {
      topRow[2] = <Annotation style={this.getStyle(2, 0, 'annotation')} key={'annotation'} />;
    } else {
      topRow[2] = <div style={this.getStyle(2, 0, 'annotation-2')} key={'annotation'} />;
    }
    const middleRow = [];
    // tree
    if (active.tree) {
      middleRow[0] = <Phylogeny style={this.getStyle(0, 1, 'aa')} key={'tree'} />;
    } else {
      middleRow[0] = <div style={this.getStyle(0, 1, 'aa')} key={'tree'} />;
    }
    // metadata
    if (active.meta) {
      middleRow[1] = <Metadata style={this.getStyle(1, 1, 'aa')} key={'meta'} />;
    } else {
      middleRow[1] = <div style={this.getStyle(1, 1, 'aa')} key={'meta'} />;
    }
    // blocks / metadata key
    if (active.metaKey) {
      middleRow[2] = <MetadataKey style={this.getStyle(2, 1, 'metaKey')} key={'metaKey'} />;
    } else if (active.blocks) {
      middleRow[2] = <Blocks style={this.getStyle(2, 1, 'blocks')} key={'blocks'} />;
    } else if ('gwas' in active.plots) {
      middleRow[2] = <Gwas style={this.getStyle(2, 2, 'gwas')} key={'gwas'} />;
    } else if ('line' in active.plots) {
      middleRow[2] = (<Line style={this.getStyle(2, 2, 'line')} key={'line'} />);
    } else {
      middleRow[2] = <div style={this.getStyle(2, 1, 'blocks-2')} key={'blocks'} />;
    }


    // needs improvement
    // const plots = [];

    // logo on / off
    let tips = null
    let loading = false
    // active所有元素中只要有一个的值true 就不显示 tips 
    if (!active.tree && !active.meta && !active.metaKey && !active.blocks && !active.gwas && Object.keys(active.plots).length === 0) {
      loading = true
      tips = (
        <div id="staticLogo" style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: '5px', // 根据需求调整底部偏移量
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
          boxSizing: 'border-box',
        }}>
          <ul>
            <li>请把要解析的数据集(例如tree | meta | annotation )直接拖入当前区域</li>
            <li>支持多文件，但同类文件自动合并（注意文件不会上传到我们服务器，而是浏览器直接读取您本地文件）</li>
            <li>更新数据只要拖入文件即可自动更新（或刷新页面或通过Settings &gt; clear all data）</li>
            <li>按字母 p 下载当前画面，按 s 切换设置显示</li>
            <li>目前支持的格式有：
              <div>
                <ul>
                  <li>.tre/.tree/.nex/.nexus/.newick/.new/.nwk</li>
                  <li>.csv/.tsv</li>
                  <li>.gff/.gff3</li>
                  <li>.txt</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      );
    }

    /* resizing divs */
    const vresizers = [];
    let numResizers = 2; // default
    if (!this.props.active.tree) {
      numResizers -= 1;
    }
    for (let i = 0; i < numResizers; i++) {
      vresizers[i] = (
        <Drag index={i} isCol={false} key={'row' + i.toString() + 'drag'} />
      );
    }
    const hresizers = [];
    numResizers = 2; // default
    if (!this.props.active.meta) {
      numResizers -= 1;
    }
    if (!this.props.active.blocks && !this.props.active.annotation && !this.props.active.metaKey) {
      numResizers -= 1;
    }
    for (let i = 0; i < numResizers; i++) {
      hresizers[i] = (
        <Drag index={i} isCol={true} key={'col' + i.toString() + 'drag'} />
      );
    }

    return (
      <div>
        <Settings {...this.props} />
        <div id="canvassesDiv" ref={(c) => this.node = c} key={this.key}>
          <div className="newline" />
          {!loading && topRow}
          <div className="newline" />
          {!loading && middleRow}
          {/* {plots} */}
          {tips}
          {!loading && vresizers}
          {!loading && hresizers}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  active: state.layout.active,
  colPercs: state.layout.colPercs,
  rowPercs: state.layout.rowPercs,
  logoIsOn: state.layout.logoIsOn,
  showSettings: state.layout.active ? state.misc.showSettings : null,
  showSearch: state.misc.showSearch ? state.misc.showSearch : null,
  metadata: state.metadata.metaData ? state.metadata.metaData : state
});

export default connect(mapStateToProps)(Main);
export { Main };
